@use "../../../variables" as v;

.container {
  width: v.$bg-inner-width;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.title {
  @include v.about-title;
  margin-top: 40px;
}

.cardsContainer {
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px;
  gap: 40px;
}
.card {
  width: 240px;
  text-wrap: nowrap;
  text-align: center;
  font-weight: 700;
  justify-content: center;
  padding: 20px 40px;
  text-transform: uppercase;
  border-radius: 10px;
  background: radial-gradient(
      307.91% 155.79% at 0% -38.33%,
      rgba(21, 122, 243, 0.2) 0%,
      rgba(10, 9, 15, 0) 100%
    ),
    radial-gradient(
      50% 50% at 50% 50%,
      rgba(81, 81, 81, 0) 0%,
      rgba(29, 28, 36, 0.12) 100%
    ),
    radial-gradient(
      94.14% 60.62% at 57.89% 48.75%,
      rgba(101, 171, 255, 0.08) 0%,
      rgba(27, 27, 27, 0) 67.5%
    );
}

@media screen and (max-width: 768px) {
  .cardsContainer {
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
  }
  .card {
    width: 220px;
  }
  
}