@use "../../../variables" as v;

.container {
  width: v.$bg-inner-width;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.title {
  @include v.about-title;
  margin-top: 40px;
}

.roadmap {
  width: 100%;
  padding: 40px;
  gap: 40px;
}

.roadmapMobileImage {
  display: none;
}
@media screen and (max-width: 768px) {
  .container {
    width: v.$bg-outer-width-mobile;
  }

  .roadmap {
    display: none;
  }

  .roadmapMobileImage {
    display: block;
    width: 100%;
    gap: 40px;
    padding-top: 20px;
  }
}
