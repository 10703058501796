@use "../../../variables" as v;

.container {
  width: 100vw;
  height: 283px;
  background: linear-gradient(90deg, #0f4eb8 0%, #65abff 100%);

  justify-content: center;
}

.innerContainer {
  position: relative;
}

.leftPart {
  width: 50%;
  flex-direction: column;
  gap: 20px;
}

.title {
  @include v.about-title;
  margin-top: 40px;
  @include v.slide-up;

}

.desc {
  @include v.about-desc;
  color: white;
  @include v.slide-right;

}

.image {
  right: 0;
  top: 0;
  position: absolute;
  @include v.slide-left;

}
.imageMobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .container {
    height: 600px;
    overflow: hidden;
  }

  .innerContainer {
    flex-direction: column;
  }

  .leftPart {
    margin-top: 220px;
    width: 100%;
    padding: 20px;
  }

  .title {
    margin-top: 20px;
  }

  .image {
    display: none;
  }
  .imageMobile{
    right: -80px;
    top: -40px;
    position: absolute;
    display: block;
    width: 100%;
    height: 300px;
  }
}
