@use "../../../variables" as v;

.container {
  padding: 40px 20px;
  gap: 40px;
  width: v.$bg-inner-width;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.d3xImage {
  width: 180px;
  height: 64px;
}
.title {
  @include v.about-title;
  margin-top: 40px;
}
.cardGroups {
  gap: 20px;
}
.cardsGroupContainer {
  padding: 20px;

  flex-direction: column;
  gap: 20px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.cardsContainer {
  flex-direction: column;
  gap: 20px;
}
.card {
  flex-direction: column;
  gap: 20px;
}
.cardDesc {
  @include v.about-desc;
}
.desc,
.cardGroupTitle,
.footerDesc {
  @include v.about-desc;
}
.footerDesc {
  max-width: 600px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .container {
    width: v.$bg-inner-width-mobile;
  }

  .cardGroups {
    flex-direction: column;
    gap: 10px;
  }
}
