@use "../../variables" as v;

.container {
    max-width: 1200px;
}

.innerSectionContainer {
    width: v.$bg-inner-width;
}

.outerSectionContainer {
    width: v.$bg-outer-width;
}

@media screen and (max-width: 768px) {
    .container {
       max-width: v.$bg-outer-width-mobile;
    }
    .innerSectionContainer {
        width: v.$bg-inner-width-mobile;
    }
}