@use "../../../variables" as v;

.container {
  margin-top: 40px;
  padding-top: 40px;
  flex-direction: column;
  align-items: center;
  width: v.$home-component-width;

  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.02);
  background: var(
    --111,
    radial-gradient(
      40.99% 56.7% at 78.47% 28.07%,
      rgba(21, 122, 243, 0.2) 0%,
      rgba(10, 9, 15, 0) 100%
    ),
    radial-gradient(
      50% 50% at 50% 50%,
      rgba(81, 81, 81, 0) 0%,
      rgba(29, 28, 36, 0.12) 100%
    ),
    radial-gradient(
      94.14% 60.62% at 57.89% 48.75%,
      rgba(101, 171, 255, 0.08) 0%,
      rgba(27, 27, 27, 0) 67.5%
    )
  );
  backdrop-filter: blur(10px);
}

.title {
  font-size: 24px;
  font-weight: 900;
  line-height: 20px;
}

.desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 20px;
  text-align: center;
  padding: 20px 40px;
  color: v.$secondary-color;
}

.viewBtn {
  color: v.$secondary-color;
  border-radius: 50px !important;
  background-color: transparent;
  color: #686868 !important;
  background: transparent !important;
  border-radius: 26px;
  border: 1px solid v.$secondary-color;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
}

.image {
  margin-top: 60px;
  width: 160px;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .container {
    width: v.$bg-inner-width-mobile;
  }
  .title {
    font-size: 20px;
  }
  .desc {
    font-size: 14px;
  }
  .image {
    width: 120px;
  }
  
}