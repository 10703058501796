@use "../../../variables" as v;

.container {
  position: relative;
  width: v.$bg-inner-width;
  padding-bottom: 108px;

}

.rankBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 55%;
  height: 260px;
}

.targetBg {
  position: absolute;
  top: 100px;
  right: 0;
  width: 50%;
  height: 260px;
}

.rankContainer {
  padding: 40px;
  width: 400px;
  flex-direction: column;
  gap: 20px;
  @include v.slide-down;

}

.targetContainer {
  text-align: right;
  position: absolute;
  padding: 40px;
  width: 400px;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  right: 0;
  top: 100px;
  @include v.slide-up;

}

.rankText,
.targetText {
  @include v.about-title;
}

.rankDesc,
.targetDesc {
  @include v.about-desc;
}

.rankImage,
.targetImage {
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 768px) {
  .container {
    width: v.$bg-outer-width-mobile;
    padding-bottom: 218px;
  }
  .rankBg {
    width: 85%;
    height: 200px;
  }
  .targetBg {
    top: 200px;
    width: 85%;
    height: 200px;
  }
  .rankContainer {
    width: 100%;
    padding: 20px;
  }
  .targetContainer {
    width: 100%;
    padding: 20px;
    align-items: flex-end;
    top: 200px;
  }

  .rankImage,
  .targetImage {
    width: 30px;
    height: 30px;
  }
  
}