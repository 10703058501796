@use "../../../variables" as v;
.container {
  margin-top: 80px;
}
.title {
  font-size: 64px;
  letter-spacing: 10px;
  font-weight: 800;

  background: linear-gradient(180deg, #fff 0%, #8199e2 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @include v.slide-up;
}

.desc1 {
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 2px;
  margin-top: 20px;
  text-transform: uppercase;

  background: linear-gradient(180deg, #fff 0%, #8199e2 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @include v.slide-left;
}
.desc2 {
  font-size: 20px;
  margin-top: 20px;
  opacity: 0.7;

  background: linear-gradient(180deg, #fff 0%, #8199e2 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @include v.slide-right;
}
.btn {
  margin-top: 50px;
  border-radius: 50px !important;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 100px;
    width: v.$bg-outer-width-mobile;
  }
  .title {
    font-size: 48px;
  }
  .desc1 {
    font-size: 24px;
    text-wrap: wrap;
    width: 80%;
    text-align: center;
  }
  .desc2 {
    font-size: 16px;
    width: 80%;
    text-align: center;
  }
  .btn {
    margin-top: 30px;
  }
}
