@use "../../variables" as v;
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: v.$header-height;
  width: 100vw;
  // max-width: v.$bg-inner-width;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  user-select: none;
  position: relative;
}

.innerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  height: v.$header-height;
  width: v.$bg-inner-width;
}

.logo {
  display: inline-block;
  cursor: pointer;
  width: 100px;
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}
.mobileMenu,
.mobileMenuContainer {
  display: none;
}

@media screen and (max-width: 768px) {
  .links {
    display: none !important;
  }
  .container {
    height: v.$header-height-mobile;
  }
  .innerContainer {
    height: v.$header-height-mobile;
    width: v.$bg-inner-width-mobile;
  }
  .mobileMenu {
    display: block;
  }
  .mobileMenuContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: end;
    position: absolute;
    position: absolute;
    width: 200px;
    top: 10px;
    right: -300px;
    background-color: #0d192e;
    border-radius: 12px;
    padding: 20px 10px;
    z-index: 200;

    transition: 0.2s ease;
  }
  .hide {
    right: -300px;
  }
  .show {
    right: 10px;
  }
}
