@use "../../../variables" as v;
.container {
  margin-top: 100px;
  padding: 40px;
  width: 800px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.02);
  background: linear-gradient(165deg, rgba(0, 0, 0, 0) 60%, #157af3 100%) bottom
      left / 50% 50% no-repeat,
    linear-gradient(195deg, rgba(0, 0, 0, 0) 60%, #157af3 100%) bottom right /
      50% 50% no-repeat;
  backdrop-filter: blur(10px);
  @include v.slide-up;
}

.background::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  border-bottom: 100px solid #0b192b;
  z-index: 1;
}
.title {
  font-size: 24px;
  font-weight: 900;
  line-height: 20px;
}

.card {
  display: flex;
  padding: 20px;
  width: 240px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.02);
  background: radial-gradient(
      126.37% 46.92% at 12.52% 71.78%,
      rgba(29, 58, 210, 0) 0%,
      rgba(11, 10, 16, 0.2) 100%
    ),
    radial-gradient(
      40.99% 56.7% at 78.47% 28.07%,
      rgba(21, 122, 243, 0.2) 0%,
      rgba(10, 9, 15, 0) 100%
    ),
    radial-gradient(
      50% 50% at 50% 50%,
      rgba(81, 81, 81, 0) 0%,
      rgba(29, 28, 36, 0.12) 100%
    ),
    radial-gradient(
      94.14% 60.62% at 57.89% 48.75%,
      rgba(101, 171, 255, 0.08) 0%,
      rgba(27, 27, 27, 0) 67.5%
    );
  backdrop-filter: blur(10px);
}

@media screen and (max-width: 768px) {
  .container {
    width: v.$bg-inner-width-mobile;
  }
  .card {
    width: 100%;

  }
  .cardsContainer {
    flex-direction: column;
  }
}
