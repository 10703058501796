@use "../../../variables" as v;

.container {
  padding: 40px 20px;
  gap: 40px;
  width: v.$bg-inner-width;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.d3xImage {
  width: 180px;
  height: 64px;
}
.title {
  @include v.about-title;
}
.cardGroups {
  gap: 20px;
  flex-wrap: wrap;
  justify-content: start;
}
.cardsGroupContainer {
  width: calc(50% - 10px);
  padding: 20px;

  flex-direction: column;
  gap: 20px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.cardsContainer {
  flex-direction: column;
  gap: 20px;
}
.card {
  flex-direction: column;
  gap: 20px;
}
.cardDesc {
  @include v.about-desc;
}
.desc,
.footerDesc {
  @include v.about-desc;
}
.footerDesc {
  max-width: 600px;
  text-align: center;
}
.cardGroupTitle {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .container {
    width: v.$bg-outer-width-mobile;
  }
  .cardGroups {
    flex-direction: column;
  }
  .cardsGroupContainer,
  .card {
    width: 100%;
  }
}
