@use "../../../variables" as v;

.container {
  width: 100vw;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.innerContainer {
  width: v.$bg-inner-width;
  justify-content: center;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.componentContainer {
  padding: 40px;
  width: v.$home-component-width;
  justify-content: space-between;
}

.image {
  width: 360px;
  height: 320px; 
  align-self: center;
}
.cardsWrapper {
  width: calc(50% - 10px);
  gap: 20px;
  flex-direction: column;
}
.star {
  width: 20px;
  height: 20px;
  border-radius: 50px;
}
.card {
    padding: 20px;
  width: 100%;
  flex-direction: column;

  border-radius: 20px;
}
.redCard {
  background: radial-gradient(
      93.25% 109.17% at 78.47% 28.07%,
      rgba(195, 136, 241, 0.2) 0%,
      rgba(10, 9, 15, 0) 100%
    ),
    radial-gradient(
      50% 50% at 50% 50%,
      rgba(81, 81, 81, 0) 0%,
      rgba(29, 28, 36, 0.12) 100%
    ),
    radial-gradient(
      94.14% 60.62% at 57.89% 48.75%,
      rgba(101, 171, 255, 0.08) 0%,
      rgba(27, 27, 27, 0) 67.5%
    );
}
.blueCard {
  background: radial-gradient(
      78.93% 90.17% at 78.47% 28.07%,
      rgba(27, 50, 255, 0.2) 0%,
      rgba(10, 9, 15, 0) 100%
    ),
    radial-gradient(
      50% 50% at 50% 50%,
      rgba(81, 81, 81, 0) 0%,
      rgba(29, 28, 36, 0.12) 100%
    ),
    radial-gradient(
      94.14% 60.62% at 57.89% 48.75%,
      rgba(101, 171, 255, 0.08) 0%,
      rgba(27, 27, 27, 0) 67.5%
    );
}

.cardTitle {
  padding: 4px 4px;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;

  border-radius: 20px;
}
.cardTitleRed {
  background: radial-gradient(
      70.71% 70.71% at 50% 50%,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      92deg,
      rgba(192, 112, 216, 0.3) 15.83%,
      rgba(23, 23, 23, 0.3) 63.01%
    ),
    rgba(255, 255, 255, 0.1);
}
.cardTitleBlue {
  background: radial-gradient(
      70.71% 70.71% at 50% 50%,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      92deg,
      rgba(0, 17, 168, 0.44) 15.83%,
      rgba(23, 23, 23, 0.3) 63.01%
    ),
    rgba(255, 255, 255, 0.1);
}
.cardDesc {
    margin-top: 10px;
    font-size: 14px;
    color: v.$secondary-color;
}
.list{
    font-size: 14px;
    color: v.$secondary-color;
    padding:  0 20px;
    gap: 10px;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
  .componentContainer {
    width: v.$bg-inner-width-mobile;
  }
  .innerContainer{
    width: v.$bg-inner-width-mobile;
  }
  .componentContainer{
    flex-direction: column;
  }
  .cardsWrapper {
    width: 100%;
  }
  .card {
    width: 100%;
  }
  .image {
    width: 100%;
    height: 100%;
  }
  .cardTitle {
    font-size: 14px;
  }
  .cardDesc {
    font-size: 12px;
  }
  .list{
    font-size: 12px;
  }
  
}