@use "../../../variables" as v;

.container {
  width: v.$bg-inner-width;
  height: 400px;
  justify-content: center;
}

.innerContainer {
  position: relative;
}

.leftPart {
  padding-left: 40px;
  width: 60%;
  flex-direction: column;
  gap: 20px;
}

.title {
  @include v.about-title;
  margin-top: 100px;
  @include v.slide-down;

}

.desc {
  @include v.about-desc;
  color: white;
  @include v.slide-up;

}

.image {
  height: 400px;
  right: 0;
  top: 0;
  position: absolute;
  z-index: -1;
  @include v.slide-left;

}

@media screen and (max-width: 768px) {
  .container {
    width: v.$bg-outer-width-mobile;
    height: 580px;
    justify-content: center;
  }
  .innerContainer{
    flex-direction: column;
  }
  .leftPart {
    width: 300px;
    padding-top: 160px;
  }
  .image {
    height: 242px;
    right: 0;
    top: 0;
    position: absolute;
    z-index: -1;
  }
}
