@use "../../variables" as v;
.container {
  width: v.$bg-outer-width;
  align-items: center;
  position: relative;
}

.bgImage {
  width: v.$bg-outer-width;
  position: absolute;
  top: 0;
}
.leftPart {
  flex-direction: column;
  width: 40%;
  padding-top: 100px;
  padding-left: 100px;
  .logo {
    width: 180px;
    stroke-width: 1px;
    stroke: rgba(255, 255, 255, 0.1);
  }
  .contactWrapper {
    height: 200px;
    position: relative;
    align-items: center;
    justify-content: center;
    .contactImage {
      width: 400px;
      position: absolute;
      z-index: -1;
    }
    .contactText {
      margin-left: 24px;
    }
  }
}
.rightPart {
  width: 60%;
  justify-content: space-around;
}

.rightPart,
.leftPart {
  z-index: 1;
}

.linkTitle {
  color: v.$secondary-color;
}
@media screen and (max-width: 768px) {
  .container {
    width: v.$bg-outer-width-mobile;
  }
  .leftPart {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 100px 0;
    .logo {
      width: 180px;
      stroke-width: 1px;
      stroke: rgba(255, 255, 255, 0.1);
    }
    .contactWrapper {
      width: v.$home-component-width-mobile;
      height: 200px;
      position: relative;
      align-items: center;
      justify-content: center;
      .contactImage {
        width: 100%;
        position: absolute;
        left: -30px;
        z-index: -1;
      }
      .contactText {
        margin-left: 0px;
      }
    }
  }
  .rightPart {
    display: none !important;
  }
}
