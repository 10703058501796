@use "../../../variables" as v;

.container {
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 40px;
  width: v.$bg-outer-width;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top: none;
}
.title {
  font-size: 24px;
  font-weight: 900;
  line-height: 20px; /* 83.333% */
}
.newsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
}
.news {
  font-size: 14px;
  width: 335px;
  padding: 20px;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.title {
  font-size: 24px;
  font-weight: 900;
  line-height: 20px; /* 83.333% */
}
.newsTitle {
  white-space: nowrap; /* Prevents text from wrapping to a new line */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds the ellipsis (...) to indicate overflow */
  max-width: 100%;
}
.desc {
  color: v.$secondary-color;
}
.link {
  color: #0f4eb8;
}

@media screen and (max-width: 768px) {
  .container {
    width: v.$bg-outer-width-mobile;
  }
  .newsTitle {
    white-space: nowrap; /* Prevents text from wrapping to a new line */
    overflow: hidden; /* Hides the overflow text */
    text-overflow: ellipsis; /* Adds the ellipsis (...) to indicate overflow */
    max-width: 100%;
  }
  .desc {
    text-align: center;
  }
}
