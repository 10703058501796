@use "../../../variables" as v;

.container {
  width: v.$bg-inner-width;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.innerContainer {
  padding: 40px;
  gap: 40px;
  position: relative;
  align-items: center;
}

.leftPart {
  width: 300px;
  height: 200px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #000;
}
.rightPart {
  width: 60%;
  flex-direction: column;
  gap: 20px;
}

.title {
  @include v.about-title;
  @include v.slide-up;

}

.desc {
  @include v.about-desc;
  @include v.slide-left;

}
@media screen and (max-width: 768px) {
  .container {
    width: v.$bg-inner-width-mobile;
  }
  .innerContainer {
    flex-direction: column;
  }
  .rightPart {
    width: 100%;
  }
}
