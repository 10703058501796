@use "../../../variables" as v;

.container {
  flex-direction: column;
  gap: 40px;
  align-items: center;
  width: v.$bg-outer-width;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  .futureApplicationWrapper {
    width: v.$home-component-width;
  }
}
