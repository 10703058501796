$header-height: 80px;
$bg-outer-width: 1200px;
$bg-inner-width: 958px;

$home-component-width: 800px;
$secondary-color: #686868;

@mixin about-title {
  color: #fff;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 28px; /* 83.333% */
}

@mixin about-desc {
  color: #686868;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
}

// 动画, 从下方渐入
@mixin slide-up {
  animation: slideUpFrames 0.3s ease-out;
}
// 动画，渐入
@mixin fade-in {
  animation: fadeInFrames 0.3s ease-out;
}
@mixin slide-down {
  animation: slideDownFrames 0.3s ease-out;
}
@mixin slide-right {
  animation: slideRightFrames 0.3s ease-out;
}
@mixin slide-left {
  animation: slideLeftFrames 0.3s ease-out;
}

@keyframes fadeInFrames {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideUpFrames {
  0% {
    transform: translateY(60px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

// slide down
@keyframes slideDownFrames {
  0% {
    transform: translateY(-60px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
//slide right
@keyframes slideRightFrames {
  0% {
    transform: translateX(-60px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
//slide left
@keyframes slideLeftFrames {
  0% {
    transform: translateX(60px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

$header-height-mobile: 60px;
$bg-outer-width-mobile: 100vw;
$bg-inner-width-mobile: calc(100vw - 20px);

$home-component-width-mobile: calc(100vw - 40px);
