@use "../../../variables" as v;

.container {
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.title {
  @include v.about-title;
  margin-top: 40px;
}

.cardsContainer {
  padding: 40px;
  gap: 40px;
}
.card {
  width: 220px;
  text-align: center;
  font-weight: 700;
  justify-content: center;
  padding: 20px 40px;
  text-transform: uppercase;
  border-radius: 10px;
}

.cardContent {
    flex-direction: column;
    text-wrap: nowrap;
    align-items: center;
    justify-content: center;
}
.userTitle {
    color: v.$secondary-color;
}

@media screen and (max-width: 768px) {
  .cardsContainer{
    flex-direction: column;
    gap: 10px;
  }
}