@use "../../../variables" as v;

.container {
  flex-direction: column;
  padding: 40px;
  gap: 40px;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: v.$bg-outer-width;
}

.title {
  font-size: 24px;
  font-weight: 900;
  line-height: 20px; /* 83.333% */
}

.image {
  width: 180px;
  height: 64px;
  stroke-width: 1px;
  stroke: rgba(255, 255, 255, 0.1);
}

.desc {
  color: v.$secondary-color;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

@media screen and (max-width: 768px) {
  .container {
    width: v.$bg-outer-width-mobile;
  }
  .desc {
    text-align: center;
  }
  
}