@use "./variables" as v;

.container {
  background: transparent;
  // background: #0a090f;
  font-family: "Roboto", sans-serif;
  color: #fff;
  position: relative;
}

.bgLayerWrapper {
  position: absolute;
  background: #0a090f;
  // min-height: 4000px;
  height: calc(100% + 40px);
  width: 100vw;
  z-index: -1;

  display: flex;
  justify-content: center;
  overflow-y: hidden;
}

.bgLayer {
  width: v.$bg-outer-width;
}

.bg1 {
  width: 100%;
  max-width: v.$bg-outer-width;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.threeLens {
  width: 100%;
  max-width: v.$bg-inner-width;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: v.$header-height;
  left: 50%;
  transform: translateX(-50%);
}
.polygon1,
.polygon2,
.polygon3 {
  position: absolute;
}
.polygon1 {
  top: 600px;
  left: calc((100vw - v.$bg-inner-width) / 2);
}
.polygon2 {
  width: 300px;
  height: 600px;
  top: 900px;
  left: calc(((100vw - v.$bg-inner-width) / 2) + 400px);
}
.polygon3 {
  width: 300px;
  height: 600px;
  top: 1000px;
  right: calc((100vw - v.$bg-inner-width) / 2);
}
